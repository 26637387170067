.doc-container{
    width: 90%;
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.doc-container:nth-child(even) {
    background: #fcf4d0;
  }

#upload-container{
    display: flex;
    align-items: center;
    margin: 2rem;
}

.file-select{
    margin: 0 2rem;
    height: 20px;
    width: 20px;
}