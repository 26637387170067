#navbar{
    background-color: #fed42a;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 70px;
    position: fixed;
    top: 0;
    left: 0;

}

#nav-logo{
    height: 60px;
}