#add-rota-btn{
    width: 225px;
    height: 40px;
    background-color: #266;
    color: white;
    font-weight: bold;
    border: unset;
    border-radius: 5px;
    font-size: 1rem;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#add-rota-btn:hover{
    background-color: rgb(21, 63, 63);
    transition: 0.5s;
}

.checkbox-label{
    margin-left: 1rem;
    font-weight: bold;
    color: #266;
}

#calendar-container > div > div.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > div:nth-child(3) > div > button.fc-shiftButton-button.fc-button.fc-button-primary,
#calendar-container > div > div.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > div:nth-child(3) > div > button.fc-holidayButton-button.fc-button.fc-button-primary,
#calendar-container > div > div.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > div:nth-child(3) > div > button.fc-sicknessButton-button.fc-button.fc-button-primary,
#calendar-container > div > div.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > div:nth-child(1) > div > button.fc-prev-button.fc-button.fc-button-primary,
#calendar-container > div > div.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > div:nth-child(1) > div > button.fc-next-button.fc-button.fc-button-primary{
    background-color: #266;
    border-color: #266;
}

.fc .fc-non-business{
    background-color: rgba(127, 127, 127, 0.3) !important;
  }

  #calendar-container{
    width: 100%;
  }
  
  #calendar-page{
    display: flex;
    flex-direction: row ;
    justify-content: space-evenly;
  }
  

  #booking-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.shift-info{
    color: #6e767b;
}

.shift-header{
    color: #266867;
}

.shift-date{
    height: 55px;
    box-sizing: border-box;
    border: 1px solid #9e9e9e ;
    border-radius: 4px;
    width: 200px;
    padding: 10px;
    margin: 1rem auto;
}

.date-label{
    font-weight: bold;
    color: #266867;
    margin-right: 10px;
    margin-left: 10px;
}

.fc-event {
    border-width: 3px;
    padding-left: 5px;
}
  
#existing-rota-btn{
    width: 200px;
    height: 40px;
    background: unset;
    border: unset;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 5px;
    border: 2px solid #266;
    color: #266;
    
}

#new-rota-btn{
    margin: 0 1rem 1rem 0;
    width: 250px;
    height: 40px;
    border: unset;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 5px;
    background-color: #ffaf26;
    color: white;
}


.unpublished-row:hover{
    cursor: pointer;
}

.new-rota-input{
    height: 50px;
    width: 100%;
    border: 2px solid grey;
    border-radius: 3px;
    margin: 0.5rem 0;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
}

.modal-cancel{
    width: 150px;
    height: 40px;
    background: unset;
    border: unset;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 5px;
    border: 2px solid #266;
    color: #266;
}

.modal-confirm{
    width: 150px;
    height: 40px;
    background: unset;
    border: unset;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 5px;
    background-color: #266;
    color: white;
}

.disabled{
    width: 150px;
    height: 40px;
    background: unset;
    border: unset;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 5px;
    background-color: grey;
    color: white;
}

.btn-container{
    display: flex;
    justify-content: space-evenly;
}

.rota-table tr:nth-child(even) {
    background: #dfefed;
  }

.rota-table tr  *{
    margin: 5px;
}

.rota-table {
    width: 100%;
    border-collapse: collapse
}

.rota-table th{
    text-align: left;
    padding: 0.25rem;
}

.table-titles{
    display: inline-flex;
    margin: 1rem 30px 1rem 0 !important;
}