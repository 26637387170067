#calendar{
    max-width: 1100px;
    margin: 2rem auto;
}

.calendar-input{
    height: 50px;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 2px solid grey;
    margin: 5px 0;
    font-size: 16px;
}