#dog-table{
    width: 1300px;
    margin: 0 auto;
    max-width: calc(100vw - 80px);
    border-collapse:collapse
}

.dog-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}



#dog-table tr:hover{
    cursor: pointer;
}



#dog-table th{
    text-align: left;
    padding: 1rem;
    
}

#thead-tr{
    border-bottom: 1px solid #434C5E;
}

#dog-table td{
    padding: 1rem;
    text-align: left;
}   

#dog-table tr:nth-child(even) {
    background: #fcf4d0;
  }

.dog-input{
    height: 50px;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    border: 2px solid grey;
    margin: 5px 0;
    font-size: 16px;
  }