* {
    box-sizing: border-box;
    font-family: 'Albert Sans', sans-serif;
    margin: 0;
}

a{
    text-decoration: none;
    color: #ffaf26;
    
}
body{
    margin: 0;
    padding: 0;
    color: #131313;
}

.success-btn, .cancel-btn{
    width: 150px;
    height: 40px;
    border-radius: 20px;
    border: unset;
    margin: 1rem 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success-btn{
    background-color: #ffaf26;
    color: white;
}

.success-btn:hover, .cancel-btn:hover{
    cursor: pointer;
}